<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <router-link :to="{ name: 'company.dashboard' }">
                  <i class="uil-home-alt"></i>
                </router-link>
              </li>
              <li>{{ $t("users.users") }}</li>
              <li>{{ $t("users.add_user") }}</li>
            </ul>
          </nav>
        </div>

        <ul id="userAddOptions" class="uk-tab page-tab" uk-switcher="animation: uk-animation-fade">
          <li>
            <a href="#" id="add_from_manuel">
              <h4>
                <i class="uil-user-plus"></i> {{ $t("users.add_manuel") }}
              </h4>
            </a>
          </li>
          <li>
            <a href="#" id="add_from_excel">
              <h4><i class="uil-table"></i> {{ $t("users.add_to_excel") }}</h4>
            </a>
          </li>
          <li>
            <a href="#">
              <h4>
                <i class="uil-chat-bubble-user" id="add_from_hr"></i>
                {{ $t("users.hr_integration") }}
              </h4>
            </a>
          </li>
        </ul>

        <ul class="uk-switcher">
          <li>
            <template v-if="is_error || formErrors.length">
              <div v-for="error in formErrors" class="uk-alert-danger" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p style="color: red">
                  <i class="uil-exclamation-triangle"></i> {{ error }}
                </p>
              </div>
            </template>
            <div class="card mt-4">
              <form action="" class="regular-form" enctype="multipart/form-data">
                <div class="uk-grid-collapse uk-child-width-1-2@s" uk-grid>
                  <div class="uk-padding-large position-relative">
                    <h4>
                      <i class="uil-user-circle"></i>
                      {{ $t("users.information") }}
                    </h4>
                    <div class="uk-position-relative my-4">
                      <label class="col-form-label" for="name">{{
                  $t("users.photo")
                }}</label>
                      <div class="user-profile-photo m-auto rounded-circle bg-light">
                        <img id="profile-image-holder" class="border border-light" src="" alt="" />
                      </div>
                      <div class="uk-position-center">
                        <div uk-form-custom style="cursor: pointer">
                          <input ref="file" type="file" accept="image/*" @change="onChangeFile" class="m-0" />
                          <span class="icon-feather-camera icon-medium text-black mt-4"></span>
                        </div>
                      </div>

                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="name">{{ $t("users.firstname")
                          }}<span class="required">*</span></label>
                        <input id="name" v-model="form.name" class="form-control" name="name" placeholder="" required=""
                          type="text" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="surname">{{ $t("users.lastname")
                          }}<span class="required">*</span></label>
                        <input id="surname" v-model="form.surname" class="form-control" name="surname" placeholder=""
                          required="" type="text" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="mail">{{ $t("general.email")
                          }}<span class="required">*</span></label>
                        <input id="mail" v-model="form.email" class="form-control" name="mail" placeholder=""
                          required="" type="email" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="phone">{{ $t("general.phone")
                          }}<span v-if="form.login_type_id === 2 ||
                  form.authentication_type_id === 3
                  " class="required">*</span></label>
                        <!-- <div class="uk-form-group">
              <label class="uk-form-label"> {{ $t('register.phone') }}</label> -->
                        <div class="uk-position-relative w-100">
                          <vue-tel-input v-model="form.phone" :inputOptions="options"></vue-tel-input>
                        </div>
                        <!-- </div> -->
                        <!-- <input id="phone" v-model="form.phone" class="form-control" name="phone" placeholder=""
                          required="" type="text" /> -->
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="sicil">{{ $t("users.reg_no")
                          }}<span v-if="form.login_type_id === 4" class="required">*</span></label>
                        <input id="sicil" v-model="form.registration_number" class="form-control" name="sicil"
                          placeholder="" type="text" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="sicil">Tc kimlik No<span v-if="form.login_type_id === 3"
                            class="required">*</span></label>
                        <input id="national_id" v-model="form.national_id" class="form-control" name="national_id"
                          placeholder="" type="text" />
                      </div>
                    </div>
                    <!-- <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="sicil">Telefon Ülke kodu<span v-if="form.login_type_id === 3"
                            class="required">*</span></label>
                        <input id="national_id" v-model="form.country_phone_code" class="form-control"
                          name="country_phone_code" placeholder="" type="text" />
                      </div>
                    </div> -->

                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="dob">{{
                  $t("users.dob")
                }}</label>
                        <input id="dob" v-model="form.dob" class="form-control" name="dob" placeholder="" required=""
                          type="date" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="dob">{{
                  $t("profile.login_type")
                }}</label>
                        <select name="login_type" class="form-control" v-model="form.login_type_id">
                          <option v-for="item in login_types" :value="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="dob">{{
                  $t("profile.authentication_type")
                }}</label>
                        <select name="authentication_type" class="form-control" v-model="form.authentication_type_id">
                          <option v-for="item in authentication_types" :value="item.id" :key="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label">Bilgilendirme Yöntemi</label>
                        <select name="notification_method" class="form-control" v-model="form.notification_method">
                          <option v-for="item in notification_methods" :value="item.value" :key="item.value">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group row mt-4">
                      <div class="col-md-12">
                        <h4>
                          <i class="uil-user-circle"></i>
                          {{ $t("users.user_add_to_group") }}
                        </h4>
                        <!-- <multiple-select-input :model.sync="userGroup" :required="false" :searchable="true"
                          :values="formattedUserGroup" :showLabels="false" @select="form.user_group = $event.id" /> -->
                        <multiselect v-model="userGroup" :allow-empty="true" :multiple="true"
                          :options="formattedUserGroup" :searchable="true" deselectLabel="" label="name"
                          selectLabel="Seçiniz" :tag-placeholder="$t('general.select')"
                          :placeholder="$t('general.select')" track-by="id">
                          <template slot="option" slot-scope="option">
                            {{ option.option.name }}
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <hr />
                    <div class="form-group row mt-3 input-checkbox">
                      <div class="col-md-12">
                        <h4>
                          <i class="uil-asterisk"></i>
                          {{ $t("users.user_password") }}
                        </h4>
                        <div class="d-flex">
                          <input id="user-password" v-model="passwordStatus" checked type="checkbox" />
                          <label class="ml-2" for="user-password">{{
                  $t("users.user_password_auto")
                }}</label>
                        </div>
                        <label class="col-form-label" for="pass">{{ $t("general.password")
                          }}<span class="required">*</span></label>
                        <input id="pass" v-model="form.password" class="form-control" disabled name="pass"
                          placeholder="" required="" type="password" />
                      </div>
                    </div>
                  </div>
                  <div class="uk-padding-large">
                    <h4>
                      <i class="uil-bag"></i>
                      {{ $t("users.business_information") }}
                    </h4>

                    <div v-if="isSuperAdmin" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="institution">
                          {{ $t("users.institution") }}
                          <span class="required">*</span>
                        </label>
                        <multiple-select-input :model.sync="company" :required="false" :searchable="true"
                          :values="formattedInstitution" :showLabels="false" @select="
                  form.company_id = $event.id;
                getValues();
                " />
                      </div>
                    </div>

                    <div v-if="form.company_id" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="position">{{
                  $t("users.mission")
                }}</label>
                        <multiple-select-input :model.sync="position" :required="false" :searchable="true"
                          :values="formattedPosition" :showLabels="false" @select="form.position_id = $event.id" />
                      </div>
                    </div>

                    <div v-if="form.company_id" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="title">{{
                  $t("users.title")
                }}</label>
                        <multiple-select-input :model.sync="title" :required="false" :searchable="true"
                          :values="formattedTitle" :showLabels="false" @select="form.title_id = $event.id" />
                      </div>
                    </div>

                    <div v-if="form.company_id" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="region">
                          {{ $t("users.area") }}</label>
                        <multiple-select-input :model.sync="region" :required="false" :searchable="true"
                          :values="formattedRegion" :showLabels="false" @select="form.region_id = $event.id" />
                      </div>
                    </div>

                    <div v-if="form.company_id" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="department">{{
                  $t("users.department")
                }}</label>
                        <multiple-select-input :model.sync="department" :required="false" :searchable="true"
                          :values="formattedDepartment" :showLabels="false" @select="form.department_id = $event.id" />
                      </div>
                    </div>

                    <div v-if="form.company_id" class="form-group row">
                      <div class="col-md-12">
                        <label class="col-form-label" for="branch">
                          {{ $t("users.branch") }}</label>
                        <multiple-select-input :model.sync="branch" :required="false" :searchable="true"
                          :values="formattedBranch" :showLabels="false" @select="form.branch_id = $event.id" />
                      </div>
                    </div>

                    <div class="form-group row mt-3 input-checkbox">
                      <div class="col-md-12">
                        <div class="d-flex">
                          <input id="user-admin" v-model="form.is_admin" checked type="checkbox" value="1" />
                          <label class="ml-2" for="user-admin">{{
                  $t("users.competent")
                }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="uk-card-footer pt-0">
                  <div class="float-right">
                    <button class="uk-button uk-button-primary small" type="button" @click="formSubmit">
                      {{ $t("general.save") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li>
            <template v-if="is_error || formErrors.length">
              <div v-for="error in formErrors" class="uk-alert-danger" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p style="color: red">
                  <i class="uil-exclamation-triangle"></i> {{ error }}
                </p>
              </div>
            </template>
            <div v-if="successMessage" class="bg-gradient-success uk-light mt-4" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color: #fff">
                <i class="uil-check"></i> {{ fileInfo.count }} kullanıcı
                başarıyla aktarıldı.
              </p>
              <!-- <p style="color:#fff;"><i class="uil-times"></i> 3 kullanıcı yüklenemedi. <a href="#">Aktarılamayan kullanıcıları excel olarakindir</a></p> -->
            </div>

            <div class="card mt-4">
              <div class="uk-card-header p-0">
                <div class="float-right"></div>
              </div>
              <div class="uk-card-body p-0 uk-grid-collapse uk-grid-large" uk-grid>
                <div class="uk-padding-large position-relative uk-width-1-3@m">
                  <h5><i class="uil-table"></i> Excel dosyası yükle:</h5>
                  <div class="form-group row mb-3">
                    <div class="col-md-12">
                      <form action="api/something" enctype="multipart/form-data" method="post">
                        <input id="fileToUpload" :disabled="is_loading" accept=".xls, .xlsx" name="fileToUpload"
                          type="file" @change="setFile" @click="resetFileInfo" />
                        <input class="btn btn-outline-primary btn-sm btn-block" name="uploadfile" type="button"
                          value="Listele" @click="excelToJSON" />
                      </form>

                      <a class="mb-4" href="#" style="font-size: 14px; color: #ae71ff" @click="exportSampleFile"><i
                          class="uil-download-alt"></i> Şablon Excel
                        Dokümanını İndir</a>
                      <i class="uil-info-circle" style="font-size: 1rem"
                        uk-tooltip="title: Kullanıcıları sisteme eklemek amacıyla şablon haline getirilmiş excel dokümanını indirin; pos: bottom-center"></i>
                    </div>
                  </div>

                  <div v-if="isSuperAdmin">
                    <hr class="mt-5" />
                    <h5><i class="uil-wifi"></i> Kullanıcıları Kuruma Aktar</h5>
                    <div class="form-group row mb-3">
                      <div class="col-md-12">
                        <label class="col-form-label" for="company">{{ $t("users.institution") }} Seç
                          <span class="required">*</span></label>
                        <multiple-select-input :model.sync="company" :required="true" :searchable="true"
                          :values="formattedInstitution" :showLabels="false" @select="
                  form.company_id = $event.id;
                getValues();
                " />
                      </div>
                    </div>
                  </div>

                  <hr class="mt-5" />
                  <h5>
                    <i class="uil-users-alt"></i> Kullanıcıları Gruba Aktar
                  </h5>
                  <div class="form-group row mb-3">
                    <div class="col-md-12">
                      <label class="col-form-label" for="company">{{ $t('general.choose_group') }}</label>
                      <select v-model="user_group" class="selectpicker2">
                        <option v-for="item in formattedUserGroup" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="uk-padding-large uk-width-expand d-flex flex-column justify-content-between px-2"
                  style="border-left: 1px solid #ccc">
                  <default-loading v-if="user_loading || is_loading"></default-loading>
                  <template v-else>
                    <div class="text-right">
                      <button v-if="!is_loading && importedUsers != null" class="uk-button uk-button-primary small"
                        style="margin-top: -15px" type="button" @click="submitFile">
                        <i class="icon-feather-save"></i> Kaydet
                      </button>
                    </div>
                    <div class="uk-position-relative my-4 text-center" style="overflow-y: scroll; padding-right: 2rem">
                      <h4 v-if="is_added" class="text-left">
                        "{{ fileInfo.name }}" dosyasında
                        {{ fileInfo.count }} kişi listeleniyor
                      </h4>
                      <div class="table-responsive">
                        <table class="table align-items-center">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">
                                {{ this.$t("general.register_number") }}
                              </th>
                              <th scope="col">
                                {{
                  this.$t(
                    "general.first_name_and_last_name_or_id_no"
                  )
                }}
                              </th>
                              <th scope="col">
                                {{ this.$t("messages.email") }}
                              </th>
                              <th scope="col">TC Kimlik</th>
                              <th scope="col">Telefon Ülke kodu
                              </th>
                              <th scope="col">
                                {{ this.$t("messages.phone") }}
                              </th>
                              <th scope="col">
                                {{ this.$t("messages.password") }}
                              </th>
                              <th scope="col">
                                {{ this.$t("users.mission") }}
                              </th>
                              <th scope="col">
                                {{ this.$t("profile.title") }}
                              </th>
                              <th scope="col">{{ this.$t("users.area") }}</th>
                              <th scope="col">{{ this.$t("profile.unit") }}</th>
                              <th scope="col">{{ this.$t("users.branch") }}</th>
                              <th scope="col">
                                {{ this.$t("messages.login_type_id") }}
                              </th>
                              <th scope="col">
                                {{ this.$t("profile.authentication_type") }}
                              </th>
                              <th scope="col">
                                {{ this.$t("users.competent") }}
                              </th>
                              <th scope="col">Aktiflik Durumu</th>
                            </tr>
                          </thead>
                          <tbody class="list">
                            <template v-for="(item, index) in importedUsers">
                              <tr v-if="item" :key="index">
                                <td class="text-right">
                                  <div class="actions mr-2">
                                    <a class="btn btn-icon btn-hover btn-lg btn-circle" href="#"
                                      uk-tooltip="Listeden Kaldır" @click="removeIndex(index)">
                                      <i class="uil-times-circle text-danger"></i>
                                    </a>
                                  </div>
                                </td>
                                <td>{{ item[0] }}</td>
                                <th scope="row">
                                  <div class="media">
                                    <div class="media-body">
                                      <a class="name h6 mb-0 text-sm" href="#">{{ item[1] + " " + item[2] }}</a>
                                    </div>
                                  </div>
                                </th>
                                <td>{{ item[3] }}</td>
                                <td>{{ item[4] }}</td>
                                <td>{{ item[5] }}</td>
                                <td>{{ item[6] }}</td>
                                <td>{{ item[7] }}</td>
                                <td>{{ item[8] }}</td>
                                <td>{{ item[9] }}</td>
                                <td>{{ item[10] }}</td>
                                <td>{{ item[11] }}</td>
                                <td>{{ item[12] }}</td>
                                <td>{{ item[13] }}</td>
                                <td>{{ item[14] }}</td>
                                <td>{{ item[15] }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="text-right">
                      <button v-if="!is_loading && importedUsers != null" class="uk-button uk-button-primary small"
                        style="margin-bottom: -15px" type="button" @click="submitFile">
                        <i class="icon-feather-save"></i> Kaydet
                      </button>
                    </div>
                  </template>
                </div>
              </div>
              <div class="uk-card-footer p-0">
                <div class="float-right"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import store from "@/core/services";
import axios from "axios";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import company_module, {
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEMS as GET_INSTITUTIONS,
  ITEMS as INSTITUTIONS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

import user_module, {
  BASE_URL as USER_BASE_URL,
  CREATE_ITEM as CREATE_USER,
  ERROR as USER_ERRORS,
  IMPORT_BASE_URL as USER_IMPORT_URL,
  IMPORT_USERS,
  LOADING as USER_LOADING,
  MODULE_NAME as USER_MODULE,
  SUCCESS,
} from "@/core/services/store/user.module";

import user_group_module, {
  BASE_URL as USER_GROUP_BASE_URL,
  GET_ITEMS as GET_USER_GROUPS,
  ITEMS as USER_GROUPS,
  MODULE_NAME as USER_GROUP_MODULE,
} from "@/core/services/store/user_group.module";

import position_module, {
  BASE_URL as POSITION_BASE_URL,
  GET_ITEMS as GET_POSITIONS,
  ITEMS as POSITIONS,
  MODULE_NAME as POSITION_MODULE,
} from "@/core/services/store/position.module";

import title_module, {
  BASE_URL as TITLE_BASE_URL,
  GET_ITEMS as GET_TITLES,
  ITEMS as TITLES,
  MODULE_NAME as TITLE_MODULE,
} from "@/core/services/store/title.module";

import region_module, {
  BASE_URL as REGION_BASE_URL,
  GET_ITEMS as GET_REGIONS,
  ITEMS as REGIONS,
  MODULE_NAME as REGION_MODULE,
} from "@/core/services/store/region.module";

import department_module, {
  BASE_URL as DEPARTMENT_BASE_URL,
  GET_ITEMS as GET_DEPARTMENTS,
  ITEMS as DEPARTMENTS,
  MODULE_NAME as DEPARTMENT_MODULE,
} from "@/core/services/store/department.module";

import branch_module, {
  BASE_URL as BRANCH_BASE_URL,
  GET_ITEMS as GET_BRANCHS,
  ITEMS as BRANCHS,
  MODULE_NAME as BRANCH_MODULE,
} from "@/core/services/store/branch.module";
import { TheMask } from "vue-the-mask";
import "vue-tel-input/dist/vue-tel-input.css";
import Multiselect from "vue-multiselect";

import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
const _USER_GROUP_MODULE_NAME = USER_GROUP_MODULE;
const _COMPANY_MODULE_NAME = COMPANY_MODULE;
const _POSITION_MODULE = POSITION_MODULE;
const _TITLE_MODULE = TITLE_MODULE;
const _REGION_MODULE = REGION_MODULE;
const _DEPARTMENT_MODULE = DEPARTMENT_MODULE;
const _BRANCH_MODULE = BRANCH_MODULE;
const _USER_MODULE = USER_MODULE;

export default {
  name: "UserAdd",
  components: {
    DataTable,
    TheMask,
    DefaultLoading,
    Multiselect,
    MultipleSelectInput,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_COMPANY_MODULE_NAME, company_module);
    registerStoreModule(_USER_GROUP_MODULE_NAME, user_group_module);
    registerStoreModule(_POSITION_MODULE, position_module);
    registerStoreModule(_TITLE_MODULE, title_module);
    registerStoreModule(_REGION_MODULE, region_module);
    registerStoreModule(_DEPARTMENT_MODULE, department_module);
    registerStoreModule(_BRANCH_MODULE, branch_module);
    registerStoreModule(_USER_MODULE, user_module);
  },
  data() {
    return {
      options: {
        showDialCode: true,
        placeholder: '5XXXXXXXXX',
        styleClasses: ['uk-input', 'border-0', 'w-100', 'h-0', 'form-input', 'uk-form-input', 'p-0', 'm-0']
      },
      // options: {
      //   placeholder: "5XXXXXXXXX",
      //   styleClasses: ["form-control"],
      // },
      file: null,
      file_value: null,
      user_admin: false,
      importedUsers: null,
      passwordStatus: true,
      is_error: false,
      removedItems: [],
      user_group: "",
      company_id: null,
      successMessage: null,
      login_types: [],
      authentication_types: [],
      notification_methods: [
        { name: "Email ile bilgilendir", value: "email" },
        { name: "Sms ile bilgilendir", value: "sms" },
      ],
      fileInfo: {
        name: "",
        count: 0,
        file: null,
      },
      is_added: false,
      is_loading: false,
      company: {
        id: null,
        name: null,
      },
      userGroup: null,
      position: {
        id: null,
        name: null,
      },
      title: {
        id: null,
        name: null,
      },
      region: {
        id: null,
        name: null,
      },
      department: {
        id: null,
        name: null,
      },
      branch: {
        id: null,
        name: null,
      },
      uploded_file: false,
      form: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        dob: "",
        login_type_id: 1,
        authentication_type_id: 2,
        registration_number: "",
        user_group: null,
        company_id: null,
        branch_id: null,
        department_id: null,
        region_id: null,
        title_id: null,
        position_id: null,
        password: "",
        is_admin: false,
        store_id: null,
        photo: null,
        national_id: "",
        country_phone_code: '',
        notification_method: "email",
      },
      formErrors: [],
    };
  },
  computed: {
    labelText() {
      const baseText = this.$t("general.phone");
      if (
        this.form.authentication_type_id === 3 ||
        this.form.login_type_id === 2
      ) {
        return baseText + "*";
      }

      return baseText;
    },
    errors() {
      return store.getters[_USER_MODULE + "/" + USER_ERRORS];
    },
    success() {
      return store.getters[_USER_MODULE + "/" + SUCCESS];
    },
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set: function (newValue) { },
    },
    user_loading: {
      get: function () {
        return store.getters[USER_MODULE + "/" + USER_LOADING];
      },
      set: function (newValue) { },
    },
    isSuperAdmin() {
      let isAdmin = false;
      if (this.userInfo && this.userInfo.roles && this.userInfo.roles.length) {
        isAdmin = !!this.userInfo.roles.find((item) => item.id === 1);
      }
      return isAdmin;
    },
    branchs: {
      get() {
        return store.getters[BRANCH_MODULE + "/" + BRANCHS];
      },
      set(value) { },
    },
    departments: {
      get() {
        return store.getters[DEPARTMENT_MODULE + "/" + DEPARTMENTS];
      },
      set(value) { },
    },
    regions: {
      get() {
        return store.getters[REGION_MODULE + "/" + REGIONS];
      },
      set(value) { },
    },
    titles: {
      get() {
        return store.getters[TITLE_MODULE + "/" + TITLES];
      },
      set(value) { },
    },
    positions: {
      get() {
        return store.getters[POSITION_MODULE + "/" + POSITIONS];
      },
      set(value) { },
    },
    user_groups: {
      get() {
        return store.getters[USER_GROUP_MODULE + "/" + USER_GROUPS];
      },
      set(value) { },
    },
    institutions: {
      get() {
        return store.getters[COMPANY_MODULE + "/" + INSTITUTIONS];
      },
      set(value) { },
    },
    formattedBranch() {
      let results = [
        {
          value: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.branchs === null || !this.branchs.length) return [];

      this.branchs.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedDepartment() {
      let results = [
        {
          value: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.departments === null || !this.departments.length) return [];

      this.departments.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedRegion() {
      let results = [
        {
          value: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.regions === null || !this.regions.length) return [];

      this.regions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedInstitution() {
      let results = [
        {
          value: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.institutions === null || !this.institutions.length) return [];

      this.institutions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedUserGroup() {
      let groups = [];
      if (this.user_groups === null || !this.user_groups.length) return [];
      this.user_groups.forEach((item) => {
        if (item.status === 1 || item.status) {
          groups.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      return groups;
    },
    formattedPosition() {
      let positions = [
        {
          id: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.positions === null || !this.positions.length) return [];

      this.positions.forEach((item) => {
        positions.push({
          id: item.id,
          name: item.name,
        });
      });
      return positions;
    },
    formattedTitle() {
      let titles = [
        {
          value: null,
          name: this.$t("general.choose"),
        },
      ];
      if (this.titles === null || !this.titles.length) return [];

      this.titles.forEach((item) => {
        titles.push({
          id: item.id,
          name: item.name,
        });
      });
      return titles;
    },
  },
  methods: {
    onChangeFile() {
      this.uploded_file = true;
      let value = this.$refs.file.files[0];
      this.form.photo = value;
      let blob = URL.createObjectURL(value);
      let imageHolder = $("#profile-image-holder");
      imageHolder.attr("src", blob);
    },
    formSubmit() {
      let self = this;

      let form = self.form;
      form.country_phone_code = form.phone.substring(0, form.phone.indexOf(' ')).substring(1);
      form.phone.length > 5 ? form.phone = form.phone.substring(form.phone.indexOf(' ') + 1).replaceAll(' ', '') : form.phone = null;
      self.formErrors = [];
      // this.country_phone_code = form.phone.substring((0, form.phone.indexOf(' ')).substring(1));
      if (form.name.length == 0) {
        self.formErrors.push(self.$t("users.required.firstname"));
        self.scrollToTop();
        return false;
      }
      if (form.surname.length == 0) {
        self.formErrors.push(self.$t("users.required.lastname"));
        self.scrollToTop();
        return false;
      }
      if (form.email.length == 0) {
        self.formErrors.push(self.$t("users.required.email"));
        self.scrollToTop();
        return false;
      }

      let hasPhoneSelectedForAnyType =
        form.authentication_type_id === 3 || form.login_type_id === 2;

      let isPhoneRequired =
        hasPhoneSelectedForAnyType && form.phone.length === 0;

      if (isPhoneRequired) {
        self.formErrors.push(self.$t("users.required.phone"));
        self.scrollToTop();
        return false;
      }

      if (
        isPhoneRequired &&
        (form.phone.length !== 10 || Number.isInteger(form.phone))
      ) {
        self.formErrors.push(self.$t("users.phone.invalid"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 2 &&
        (form.phone === null || form.phone == "")
      ) {
        self.formErrors.push(self.$t("users.required.phone"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 3 &&
        (form.national_id === null || form.national_id == "")
      ) {
        self.formErrors.push(self.$t("general.required_national_id"));
        self.scrollToTop();
        return false;
      }
      if (
        form.login_type_id === 4 &&
        (form.registration_number === null || form.registration_number == "")
      ) {
        self.formErrors.push(self.$t("users.required.reg_no"));
        self.scrollToTop();
        return false;
      }
      let form_data = new FormData();
      form_data.append("name", form.name);
      form_data.append("surname", form.surname);
      form_data.append("email", form.email);
      form_data.append("phone", form.phone);
      form_data.append("dob", form.dob);
      form_data.append("registration_number", form.registration_number);
      form_data.append("national_id", form.national_id);
      form_data.append("country_phone_code", form.country_phone_code);
      form_data.append("user_group", form.user_group);
      form_data.append("company_id", form.company_id);
      form_data.append("branch_id", form.branch_id);
      form_data.append("department_id", form.department_id);
      form_data.append("region_id", form.region_id);
      form_data.append("title_id", form.title_id);
      form_data.append("position_id", form.position_id);
      form_data.append("password", form.password);
      form_data.append("is_admin", form.is_admin);
      form_data.append("login_type_id", form.login_type_id);
      form_data.append("authentication_type_id", form.authentication_type_id);
      form_data.append("notification_method", form.notification_method);
      form_data.append("photo", form.photo);
      let group_ids = [];
      if (self.userGroup !== null && self.userGroup.length > 0) {
        self.userGroup.forEach((item) => {
          form_data.append("group_ids[]", item.id);
          group_ids.push(item.id);
        });
      } else {
        form_data.append("group_ids[]", null);
      }
      this.$store.dispatch(USER_MODULE + "/" + CREATE_USER, {
        url: USER_BASE_URL,
        contents: form_data,
      });
    },
    submitFile() {
      let self = this;
      self.is_loading = true;
      self.formErrors = [];
      let formData = new FormData();
      formData.append("file", self.fileInfo.file);
      formData.append("removed", self.removedItems);
      formData.append("user_group", self.user_group);
      formData.append("company_id", self.company_id);

      if (
        this.isSuperAdmin &&
        (self.company_id === null || self.company_id === "")
      ) {
        self.is_error = true;
        self.formErrors.push(self.$t("validation.one_item_required"));

        return false;
      }

      if (!self.fileInfo.file) {
        self.is_error = true;
        self.formErrors.push(self.$t("validation.file_required"));
        return false;
      }

      if (self.fileInfo.count === self.removedItems.length) {
        self.is_error = true;
        self.formErrors.push(self.$t("validation.one_item_required"));

        return false;
      }

      this.scrollToTop();

      store
        .dispatch(USER_MODULE + "/" + IMPORT_USERS, {
          url: USER_IMPORT_URL,
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          self.is_loading = false;
          if (!self.errors) {
            self.resetFileInfo();
            self.$router.push({
              name: "company.user.list",
              params: {
                succesMessage: this.$t("general.successfully_imported"),
              },
            });
          }
        });
    },
    exportSampleFile() {
      let self = this;

      let config = {
        responseType: "blob",
        headers: this.apiHeaders,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_APP_URL + "/api/user/export-template",
          [],
          config
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "user_sample_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();

          self.showExistRecords = true;
          self.filterResultCode(self.active_item_id);
          self.filterResult();
        })
        .catch((error) => {
        });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    resetFileInfo() {
      this.is_added = false;
      this.fileInfo.file = null;
      this.importedUsers = null;
      this.removedItems = [];
      $("#fileToUpload").val("");
    },
    setFile(e) {
      this.file_value = e.target.value;
      this.file = e.target.files[0];
    },
    excelToJSON(e) {
      if (this.file) {
        this.is_loading = true;
        let fileName = this.file_value
          ? this.file_value.split(/(\\|\/)/g).pop()
          : "";
        let count;
        this.fileInfo.file = this.file;
        readXlsxFile(this.file).then((rows) => {
          delete rows[0];
          count = rows.length;
          this.updateImporteValues(rows, count, fileName);
        });
      }
    },
    removeIndex(key) {
      let obj = this.importedUsers;
      this.importedUsers = delete obj[key];
      this.importedUsers = obj;
      this.removedItems.push(key);
    },
    resetMessages() {
      this.is_error = false;
      this.formErrors = [];
      this.successMessage = null;
    },
    updateImporteValues(values, count, fileName) {
      count--;
      this.importedUsers = values;
      this.fileInfo.name = fileName;
      this.fileInfo.count = count;
      this.is_added = count > 0 ? true : false;
      this.is_loading = false;
    },
    getBranchs() {
      this.$store.dispatch(BRANCH_MODULE + "/" + GET_BRANCHS, {
        url: BRANCH_BASE_URL,
        filters: {
          company_id: this.form.company_id,
        },
      });
    },
    getDepartments() {
      this.$store.dispatch(DEPARTMENT_MODULE + "/" + GET_DEPARTMENTS, {
        url: DEPARTMENT_BASE_URL,
        filters: {
          company_id: this.form.company_id,
        },
      });
    },
    getRegions() {
      this.$store.dispatch(REGION_MODULE + "/" + GET_REGIONS, {
        url: REGION_BASE_URL,
        filters: {
          company_id: this.form.company_id,
        },
      });
    },
    getTitles() {
      this.$store.dispatch(TITLE_MODULE + "/" + GET_TITLES, {
        url: TITLE_BASE_URL,
        filters: {
          company_id: this.form.company_id,
        },
      });
    },
    getPositions() {
      this.$store.dispatch(POSITION_MODULE + "/" + GET_POSITIONS, {
        url: POSITION_BASE_URL,
        filters: {
          company_id: this.form.company_id,
        },
      });
    },
    getInstitutions() {
      this.$store.dispatch(COMPANY_MODULE + "/" + GET_INSTITUTIONS, {
        url: COMPANY_BASE_URL,
        filters: {
          all: "all",
        },
      });
    },
    getLoginTypes() {
      let types = [];
      axios
        .get(`api/user/login-types`, {
          headers: this.apiHeaders,
        })
        .then((result) => {
          result.data.forEach((data) => {
            types.push({
              id: data.id,
              name: data.name,
            });
          });
        });
      this.login_types = types;
    },
    getAuthenticationTypes() {
      let types = [];
      axios
        .get(`api/user/authentication-types`, {
          headers: this.apiHeaders,
        })
        .then((result) => {
          result.data.forEach((data) => {
            types.push({
              id: data.id,
              name: data.name,
            });
          });
        });
      this.authentication_types = types;
    },
    getUserGroups() {
      this.$store.dispatch(USER_GROUP_MODULE + "/" + GET_USER_GROUPS, {
        url: USER_GROUP_BASE_URL,
        filters: {
          all: "all",
        },
      });
    },
    getValues() {
      let temp = this.form.company_id;

      this.form.company_id = null;
      this.form.company_id = temp;

      this.getPositions();
      this.getTitles();
      this.getRegions();
      this.getDepartments();
      this.getBranchs();

      this.form.position_id = "";
      this.form.title_id = "";
      this.form.region_id = "";
      this.form.department_id = "";
      this.form.branch_id = "";
    },
  },
  mounted() {
    this.getInstitutions();
    this.getLoginTypes();
    this.getAuthenticationTypes();
    this.getUserGroups();

    if (!this.isSuperAdmin) {
      this.form.company_id = this.userInfo.company_id;
      this.getValues();
    }

    let activeTab = this.$route.params.type;

    if (activeTab) {
      setTimeout(() => {
        $("#userAddOptions li:nth-child(" + Number(activeTab) + ") i").trigger(
          "click"
        );
      }, 100);
    }
  },
  watch: {
    "form.authentication_type_id": {
      immediate: true,
      handler(newValue) {
        if (newValue === 3) {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      },
    },
    "form.login_type_id": {
      immediate: true,
      handler(newValue) {
        if (newValue === 2) {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
      },
    },
    company() {
      this.company_id = this.company.id;
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker1").selectpicker();
        }, 200);
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker2").selectpicker();
        }, 200);
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker3").selectpicker("refresh").trigger("change");
        }, 200);
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker4").selectpicker("refresh").trigger("change");
        }, 200);
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker5").selectpicker("refresh").trigger("change");
        }, 200);
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker6").selectpicker("refresh").trigger("change");
        }, 200);
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker7").selectpicker("refresh").trigger("change");
        }, 200);
      }
    },
    successMessage: function (val) {
      this.debouncedResetMessages();
    },
    success: function (val) {
      if (val) {
        this.$router.push({
          name: "company.user.list",
          params: { succesMessage: this.$t("general.create_success") },
        });
      }
    },
    errors: function (val) {
      if (val) {
        this.formErrors.push(val);
      }
    },
    passwordStatus(value) {
      if (value) {
        $("#pass").prop("disabled", true);
        this.form.password = "12345678";
      } else {
        $("#pass").prop("disabled", false);
      }
      this.form.password = "";
    },
  },
  created: function () {
    this.debouncedResetMessages = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

@import "~vue-multiselect/dist/vue-multiselect.min.css";

.uk-alert {
  margin: 0;
}

#notifications h4 {
  text-transform: none !important;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
